<template>
    <div>
        <h4>{{ activeLanguage.title }}</h4>
        <div class="card-body">
            <div class="card-text">
                <template 
                    v-for="step in steps"
                    v-if="userManageOrders && step.orderNumber <= currentStep.orderNumber"
                >
                <div>
                    <strong>{{ stepsLanguage.action }}:</strong>
                    {{step.action}}
                </div>
                <div>
                    <strong>{{ stepsLanguage.notificationDay }}:</strong>
                    {{step.daysLeft}}
                </div>
                <div>
                    <strong>Status:</strong>
                    {{step.status}}
                </div>
                </template>
                <div>
                    <strong>{{ activeLanguage.createdAt }}:</strong>
                    {{details.createdAt}}
                </div>
                <div>
                    <strong>{{ activeLanguage.category }}:</strong>
                    {{details.category}}
                </div>
                <div>
                    <strong>{{ activeLanguage.createdBy }}:</strong>
                    {{details.createdBy}}
                </div>
                <div>
                    <strong>{{ activeLanguage.resolved.title }}:</strong>
                    {{details.resolved ? activeLanguage.resolved.yes : activeLanguage.resolved.no}}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
	export default {
		name: "DisputeDetails",
		props: ['activeLanguage', 'details', 'userManageOrders', 'steps', 'stepsLanguage', 'currentStep'],
		data() {
			return {

			}
		},
	}
</script>

<style scoped>
.garanty{
    padding-top: 15px;
    margin-top: 20px;
    border-top: 1px solid #000;
}
    .card-text{
        padding: 6px 0;
    }
    .card-body {
        padding-top: 0;
    }
</style>