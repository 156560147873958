<template>
    <div
        class="collapse show"
        id="dispute-slide"
        :style="showCreditOrderModalWindow ? { display: 'none'} : ''"
        v-if="show"
    >
    <div class="help">
            <div class="title">
                <div class="links float-right">
                    <a class="close" href="#" @click.prevent="hideDisputeModal"><i class="fa fa-times" aria-hidden="true"></i></a>
                    <a
                        class="back-link"
                        href=""
                        @click.prevent="showModal"
                        v-if="!dispute.resolved"
                    >
                        <i class="fa fa-money" aria-hidden="true"></i>
                        {{ activeLanguage.creditRefund }}
                    </a>
                    <a
                        @click.prevent="openResolveMessageModal()"
                        v-if="showDisputeBtn && userManageOrders"
                        href=""
                        class="pause-link hidden-xs"
                    >
                        <i class="fa fa-times" aria-hidden="true"></i>
                        {{ activeLanguage.closeDispute }}
                    </a>
                </div>
                <h1 style="margin-left: -1px">
                    {{ activeLanguage.title }}
                    <span># {{disputeId}}</span>
                </h1>
            </div>
            <div class="row" style="margin-bottom: 20px;">
                <div class="col-12">
                    <DisputeDetails
                        :userManageOrders="userManageOrders"
                        :activeLanguage="activeLanguage.disputeDetailsTable"
                        :details="disputeDetails"
                        :steps="steps"
                        :stepsLanguage="activeLanguage"
                        :currentStep="dispute.currentStep"
                    />
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <History
                        scope="employee-dispute"
                        :activeLanguage="activeLanguage.history"
                        :history="history"
                        :disabled="dispute.resolved"
                        @firstContentAdded="getDisputeDetails"
                        @lastMessageHasAdded="resolveDispute(dispute)"
                        ref="contentContainer"
                        :key="historyKey"
                        :historyKey="historyKey"
                        @getImage="getImage"
                        :disputeId="disputeId"
                        :id="id"
                    />
                </div>
            </div>
        </div>
        <div class="credit-modal">
            <b-modal ref="creditOrderModal" hide-footer hide-header size="lg">
                <CreditOrder
                    ref="OrderDetailsCreditOrder"
                    :activeLanguage="activeLanguageCredit.addCreditBlock"
                    :orderData="orderData"
                    :items="orderItems"
                    :showModal="showCreditOrderModalWindow"
                    @close="hideModal"
                    @creditIsAdded="creditIsAdded"
                />
            </b-modal>
        </div>
    </div>
</template>

<script>
	import {mapGetters} from  "vuex"
	import moment from 'moment'
    import axios from "axios"

	import CustomerClientDetails from '@/components/disputeView/CustomerClientDetails'
	import DisputeDetails from '@/components/disputeView/DisputeDetails'
	import History from '@/components/disputeView/History'
	import CreditOrder from '@/components/orderDetailsView/CreditOrder'
    import store from '@/store/index'

	export default {
		name: "DisputeEmployeeDetails",
		data() {
			return {
				dispute: {},
				clientDetails: {},
				customerDetails: {},
				disputeDetails: {},
				history: [],
                steps: [],
				showDisputeBtn: false,
				orderData: {},
				orderItems: [],
        showCreditOrderModalWindow: false,
        historyKey: 1,
        show: false
			}
		},
        props: {
			disputeId: {
				required: true
			},
            id: {
				required: true
			}
		},
		mounted() {
		},
		methods: {
            showDisputeModal() {
                this.show = true
            },
            hideDisputeModal() {
                this.show = false
                this.$emit('removeSelectedIndex')
            },
			showModal () {
				this.$refs.creditOrderModal.show()
                this.showCreditOrderModalWindow = true;
			},
			hideModal () {
				this.$refs.creditOrderModal.hide()
                this.showCreditOrderModalWindow = false;
      },
			creditIsAdded() {
				this.hideModal()
				this.getOrderDetails(this.disputeDetails.invoiceNr, true)
            },
			getDisputeDetails(groupId, disputeId) {
				disputeAxios
					.get(`/api/employee-dispute/group-view/${groupId}`)
					.then(({ data }) => {
						this.dispute = data.dispute.find((item) => item.id === parseFloat(disputeId))
						this.dispute.disputeContent.sort((a, b) => new Date(b.createdAt.date).getTime() - new Date(a.createdAt.date).getTime())
						this.dispute.currentStep = this.dispute.currentStep || {}
						this.history = this.dispute.disputeContent
                        this.disputeGroupId = data.id

                        this.getImage()

                        if (this.dispute.currentStep.notificationDay) {
	                        this.steps = this.dispute.steps.sort((a, b) => a.orderNumber > b.orderNumber)
	                        this.steps.forEach((item) => {
		                        let a = new Date(item.notificationDay.date).getTime(),
			                        b = new Date(this.dispute.currentStep.notificationDay.date).getTime(),
			                        c = parseInt((a - b) / 86400000)

		                        item.daysLeft = c <= 0 ? this.activeLanguage.today : `${c} Day(s) Left`;
	                        })
                        }
						this.disputeDetails = {
							createdAt: moment(this.dispute.createdAt.date).format("D/MM/YYYY"),
							category: this.dispute.category,
							createdBy: this.dispute.owner.username,
							resolved: this.dispute.resolved,
							invoiceNr: data.invoiceNr
						}
            this.clientDetails = {
              email: data.dispute[0].client.email
            }

						this.showDisputeBtn = !this.dispute.resolved

						this.getOrderDetails(data.invoiceNr)
						this.getOrderClientInfo(this.disputeDetails.invoiceNr)
					})
					.catch(err => console.error(err))
			},
            getImage() {
                this.history.map(item => {
                            if(item.fileUrl && item.fileUrl !== '/') {
                                let { access_token } = store.getters.getBillinkToken

                                axios({
                                    method: "get",
                                    url: `${process.env.VUE_APP_DISPUTE_BASE_URL}api/employee-dispute/get-file${item.fileUrl}`,
                                    headers: {
                                        Authorization: `Bearer ${access_token}`,
                                    },
                                    }).then((response) => {
                                        item.imgUrl = response.data.file
                                        this.historyKey = this.historyKey + 1 
                                    });
                            }
                        })
            },
			getOrderClientInfo(inv) {
				billinkAxios
					.get(`app/order-details/${inv}/client`)
					.then(({data}) => {
						this.clientDetails = {
							username: data.username || `${data.first_name} ${data.last_name}`,
							email: this.clientDetails.email,
							phone: data.phone,
						}
                        this.customerDetails = {
                            email: data.customer_email,
                            username: `${data.customer_first_name} ${data.customer_last_name}`,
                            phone: data.customer_phone,
                            zipCity:`${data.customer_postal_code} ${data.customer_city}`
                        }
                    })
					.catch(err => console.error(err))
            },
			getOrderDetails(invoiceNr) {
				billinkAxios
					.get(`app/order-details/${invoiceNr}`)
					.then(({ data }) => {
						this.orderItems = data.items
						this.orderData = {
							order_id: parseFloat(data.id),
							amount: parseFloat(data.order_info.outstanding),
							credit: (parseFloat(data.order_info.subtotal) - parseFloat(data.order_info.credit)).toFixed(2)
						}
					})
					.catch(error => console.error(error));
			},
            orderIsCredited() {
	            let params = {
		            order: this.orderData.order_id,
		            message: 'Dispuut automatisch gesloten wegens credit.'
	            }

	            billinkAxios
		            .get('app/activity/add', { params })
		            .then(({data}) => this.resolveDisputeGroup())
		            .catch(err => console.error(err))
            },
            openResolveMessageModal() {
	            this.$refs.contentContainer.openResolveModalMessage('closing')
            },
			resolveDisputeGroup() {
				disputeAxios
					.patch(`/api/employee-dispute/group-resolve/${this.disputeGroupId}`)
					.then(({data}) => {
						if (data.code === 2010) {
							this.this.getDisputeDetails(this.id, this.groupId)
						}
					})
					.catch(err => console.error(err))
			},
			resolveDispute(dispute) {
				disputeAxios
					.patch(`/api/employee-dispute/resolve/${dispute.id}`)
					.then(({data}) => {
						if (data.code === 2010) {
							dispute.resolved = true
                            this.showDisputeBtn = false

                            if (data.orderCanBeReopened) { this.setOrderResume() }
						}
					})
                    .then(() => this.getDisputeDetails(this.id, this.groupId))
					.catch(err => console.error(err))
			},
            setOrderResume() {
	            billinkAxios
		            .post('app/order/resume', { order_id: this.orderData.order_id })
		            .then(({ data }) => {})
		            .catch(err => console.error(err))
            },
            backToOrder() {
                this.$router.push(`/order/${this.disputeDetails.invoiceNr}`)
            }
		},
		components: {
			CustomerClientDetails,
			DisputeDetails,
			History,
			CreditOrder
		},
		computed: {
			...mapGetters({
				activeLanguage: 'getDisputeDetailsLanguage',
				activeLanguageCredit: 'getOrderDetailsLanguage',
                userManageOrders: 'userManage_orders'
			}),
		},
	}
</script>

<style>
    .credit-modal .modal-body{
        padding: 0;
    }
    .credit-modal .card {
        margin-bottom: 0;
    }
    #dispute-slide .card-header {
        font-size: 20px;
        margin-top: 0 !important;
        margin-bottom: 15px;
    }
</style>

<style scoped lang="scss">
    .help {
        width: 600px;
        overflow: auto;
        padding: 0;

        h1 {
            padding-left: 20px;
            padding-top: 20px;
            padding-bottom: 20px;
            background: #eeeeee;
        }
        .title {
            margin-bottom: 0;
        }
        .links {
            padding-top: 20px;
            padding-right: 20px;
        }

        .row {
            padding-left: 20px;
        }
    }
    @media (max-width:460px) {
        .help {
            width: 100vw;
            overflow: scroll;
        }
    }
    .step {
        background: #9DCECB;
        padding: 10px;
        border-radius: 10px;
        color: #ffffff;
        margin-bottom: 15px;
        div {
            display: flex;
            justify-content: space-between;
        }
    }
    .currentStep .step {
        background: #4db5af;
    }
</style>