<template>
    <div class="card">
        <h4 class="card-header">{{activeLanguage}}</h4>
        <div class="card-body">
            <div class="card-text">
                <strong v-if="details.username">{{details.username}}</strong>
                <div v-if="details.zipCity" style="margin-bottom: 20px;">{{details.zipCity}}</div>
                <div v-if="details.phone">T: {{details.phone}}</div>
                <div v-if="details.email">E: {{details.email}}</div>
            </div>
        </div>
    </div>
</template>

<script>
	export default {
		name: "CustomerClientDetails",
		props: ['activeLanguage', 'details', 'isCustomer', 'userManageOrders'],
        data() {
			return {

            }
        },
        computed: {
        }
	}
</script>

<style scoped lang="scss">
    .card{
        height: 100%;
        margin-bottom: 0;
    }
</style>