<template>
    <div class="content-card history">
        <div class="historyHeader">
            <h4>{{activeLanguage.title }}</h4>
        </div>
        <div class="card-body">
            <ul class="card-messages imgMes">
                <li v-for="content in history" v-if="!content.hidden" class="row">
                    <div class="col-8">
                        <strong>{{`${content.hour} | ${content.date} | ${content.owner.username}`}}</strong>
                        <div>
                            <p v-html="content.message === 'File was added.' ? activeLanguage.mesModal.file : content.message"></p>
                        </div>
                    </div>
                    <div class="col-4 imgMes" style="text-align: center;">
                        <template v-if="content.isImg && content.emailContent">
                            <div style="display: flex">
                                <a href="" @click.prevent="openContentModal(content)" style="display: block;text-align: center;">
                                <img class="messageImg" :src="content.imgUrl">
                                </a>
                                <a target="_blank" href="" @click.prevent="openContentModal(content)" style="padding-left: 15px;">
                                    <img style="width: 40px;margin: 0 auto;" src="/img/icons/cemail.svg">
                                </a>
                            </div>
                            <div class="">
                                <b-modal :ref="`imgModal${content.id}`" size="lg" hide-footer :title="content.fileName">
                                    <img class="modalImg" :src="content.imgUrl" style="max-width: 100%; max-height: 85vh">
                                </b-modal>
                            </div>
                            <div class="">
                                <b-modal :ref="`imgModal${content.id}`" size="lg" hide-footer title="Email Template">
                                    <iframe :id="`iframe-${content.id}`" frameborder="0" width="100%" height="800"></iframe>
                                </b-modal>
                            </div>
                        </template>
                        <template v-else-if="content.isImg && !content.emailContent">
                            <a href="" @click.prevent="openContentModal(content)" style="display: block;text-align: center;">
                                <img class="messageImg" :src="content.imgUrl">
                            </a>
                            <div class="">
                                <b-modal :ref="`imgModal${content.id}`" size="lg" hide-footer :title="content.fileName">
                                    <img class="modalImg" :src="content.imgUrl" style="max-width: 100%; max-height: 85vh">
                                </b-modal>
                            </div>
                        </template>
                        <template v-else-if="content.isImg && content.emailContent">
                            <div style="display: inline-flex">
                                <a target="_blank" href="" @click.prevent="openContentModal(content)" style="padding-top: 6px;">
                                    <img style="width: 40px;margin: 0 auto;" src="/img/icons/cemail.svg">
                                </a>
                                <a href="" @click.prevent="openContentModal(content)" style="display: block;text-align: center; padding-left: 10px;">
                                    <img class="messageImg" :src="content.imgUrl">
                                </a>
                            </div>
                            <div class="">
                                <b-modal :ref="`imgModal${content.id}`" size="lg" hide-footer title="Email Template">
                                    <iframe :id="`iframe-${content.id}`" frameborder="0" width="100%" height="800"></iframe>
                                </b-modal>
                            </div>
                        </template>
                        <template v-else-if="content.emailContent">
                            <a target="_blank" href="" @click.prevent="openContentModal(content)" style="padding-top: 6px;">
                                <img style="width: 40px;margin: 0 auto;" src="/img/icons/cemail.svg">
                            </a>
                            <div class="">
                                <b-modal :ref="`imgModal${content.id}`" size="lg" hide-footer title="Email Template">
                                    <iframe :id="`iframe-${content.id}`" frameborder="0" width="100%" height="800"></iframe>
                                </b-modal>
                            </div>
                        </template>
                        <template v-else-if="!content.isImg && content.fileUrl === '/'">
                            <a target="_blank" :href="content.imgUrl" style="padding-top: 6px;">
                                <img style="width: 30px;margin: 0 auto;" src="/img/icons/document.svg">
                            </a>
                        </template>
                    </div>
                    <div class="col-1" style="padding-top: 25px;" v-if="false">
                        <a href="" class="open-mail">{{ activeLanguage.openMail }}</a>
                    </div>
                </li>
                <MessageModal
                    :activeLanguage="activeLanguage.mesModal"
                    :id="disputeId"
                    :disabled="disabled"
                    ref="msgModal"
                    @mesAdded="mesAdded"
                    @lastMessageHasAdded="lastMessageHasAdded"
                />
            </ul>
        </div>
        <div>
            <button
                    type="button"
                    class="btn addBtn"
                    :disabled="disabled"
                    @click.prevent="openMsgModal"
                >
                {{ activeLanguage.addMsg }}
            </button>
        </div>
    </div>
</template>

<script>
    import moment from 'moment'
    import MessageModal from './modals/MessageModal'
    import store from '@/store/index'
    import axios from "axios"

    export default {
		name: "History",
		props: ['activeLanguage', 'history', 'disabled', 'scope', 'disputeId', 'id'],
		data() {
			return {
            }
		},
        methods: {
	        openMsgModal(type) {
                this.$refs.msgModal.openMsgModal(type)
            },
	        openResolveModalMessage(type) {
		        this.$refs.msgModal.openResolveModalMessage(type)
            },
	        mesAdded(data) {
                if (data.fileUrl && data.fileUrl !== '/') {
                    let { access_token } = store.getters.getBillinkToken

                                axios({
                                    method: "get",
                                    url: `${process.env.VUE_APP_DISPUTE_BASE_URL}api/employee-dispute/get-file${data.fileUrl}`,
                                    headers: {
                                        Authorization: `Bearer ${access_token}`,
                                    },
                                    }).then((response) => {
                                        data.imgUrl = response.data.file
                                    });
                }
                this.$emit('firstContentAdded', this.id, this.disputeId)

            },
	        lastMessageHasAdded() {
		        this.$emit('lastMessageHasAdded')
            },
	        openContentModal(content) {
                this.$refs[`imgModal${content.id}`][0].show()
                setTimeout(() => {
                    if (content.emailContent) {
                        Helper.fillEmailIframe(`iframe-${content.id}`, content.emailContent)
                    }
                }, 100)
	        	
	        }
        },
		watch: {
			history: function(newVal, oldVal) {
				const tz = new Date().getTimezoneOffset(),
					calcTz = Math.abs(tz)

				newVal.forEach(content => {
					let date = moment(content.createdAt.date)

                    if (!content.emailContent) {
						if (tz <= 0) {
							date.add(calcTz, 'minute');
                        } else {
							date.subtract(calcTz, 'minute')
                        }
                    }

					content.date = date.format("D/MM/YYYY")
					content.hour = date.format("HH:mm")

                    if (content.fileUrl && content.fileUrl !== '/') {
						let ext = ['png', 'jpg', 'gif', 'jpeg', 'svg']
	                    content.isImg = ext.includes(content.fileUrl.split('.').pop().toLowerCase())
                    }
				})
			}
		},
        components: {
	        MessageModal
        }
	}
</script>

<style lang="scss">
    .content-card{
        .card-messages {
            list-style-type: none;
            padding: 0;
            li {
                display: flex;
                justify-content: space-around;
                margin-top: 20px;
                margin-bottom: 20px;
                p {
                    width: 90%;
                    text-align: left;
                }
                a {
                    text-align: right;
                }
            }
        }
        .messageImg {
            max-width: 100px;
            max-height: 100px;
        }
        .contentTextarea{
            resize: none;
            width: 100%;
            height: 60px;
            padding: 4px 8px;
        }
        .modalImg{
            max-width: 100%;
            max-height: 500px;
            display: block;
            margin: 0 auto;
        }
        iframe {
            width: 100%;
            min-height: 800px;
            max-height: 100%;
        }
    }
    .history .card-messages li{
        margin-top: 0;
    }
    .historyHeader {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        h4 {
            margin-top: auto;
            margin-bottom: auto;
        }

        .addBtn {
            height: 35px;
            margin-top: auto;
            margin-bottom: auto;
        }
    }
</style>
<style lang="scss">
    .file-wr > div:nth-child(1){
        position: absolute;
        bottom: 100%;
        left: 0;
        right: 0;
        text-align: center;
        display: none;
        font-weight: 600;
    }
    .file-wr.err > div:nth-child(1){
        color: red;
        display: block;
    }
    .file-wr.err > div:nth-child(2) .custom-file-label{
        border: 1px solid red;
        color: red;
    }
    .file-wr.err > div:nth-child(2) .custom-file-label:after{
        background-color: red;
    }
    .content-card {
        .imgMes .modal-header {
            background: #032438;
        }
        .imgMes .modal-header, .modal-title,
        .imgMes .modal-header .close{
            color: #ffffff;
        }
    }
    .custom-file-label:after {
        background: #4db5af;
        color: #ffffff;
        font-weight: 400;
    }
    .custom-file-input:lang(en)~.custom-file-label::after {
        content: "Browse";
    }
    .custom-file-input:lang(du)~.custom-file-label::after {
        content: "Bladeren";
    }
</style>